// @todo rename to main.tsx post-alpha
import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
// components
import App from './App';
// utils
import { sentryOptions } from '@/utils/sentryCommon';
import { isDev } from '@/env';
// style
import '@/styles/index.scss';
import axios from 'axios';



Sentry.init({
  ...sentryOptions,
  environment: import.meta.env.MODE,
  enabled: !isDev,
  integrations: [],
  beforeSend(event, hint) {
    const error = hint?.originalException;

    // Check if error is an Axios error or has a response object
    if (axios.isAxiosError(error) && error.response) {
      const traceId = error.response.headers?.["Dcl-Apm-Trace-Id"];
      const transactionId = error.response.headers?.["Dcl-Apm-Transaction-Id"];

      event.extra = {
        ...event.extra,
        "Dcl-Apm-Trace-Id": traceId,
        "Dcl-Apm-Transaction-Id": transactionId,
      };
    }

    return event;
  },
});

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(<App />);
